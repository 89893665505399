.dashboardSidebarMainContainer {
    position: fixed;
    display: flex;
    color: white;
    flex-direction: column;
}

@keyframes unCollpase {
    from {width: 96px}
    to {width: 280px}
}
@keyframes collapse {
    from {width: 280px}
    to {width: 96px}
}
/* .dashboardSidebarMainContainer[data-collapse = true][data-clickactivated = true]{
    animation-name: collapse ;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}
.dashboardSidebarMainContainer[data-collapse = false][data-clickactivated = true]{
    animation-name: unCollpase;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
} */
.collapse_button{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 49px;
    right: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid white;
    border-radius: 5px;
    cursor: pointer;
}
.dashboardSidebarTitleContainer{
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 40px;
    width: 100%;
    justify-content: center;
    gap: 5%;
    margin-top: 48px;
    color: white
}
.dashboardSidebarHeader{
    font-size: 24px;
    font-weight: 600;
}
.dashboardSidebarInfoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    gap: 16px
}
.DashboardElementMainContainer{
    margin-top: 30px;
}
.DashboardElementSubContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    height: 30px;
}
.dashboardLink {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    gap: 10%;
}
.dashboardLink:hover{
    background-color: #312F41;
    border-radius: 7px;
    padding: 10px 10px;
}
/* .DashboardElementSubContainer :hover{
    background-color: #312F41;
    border-radius: 7px;
    padding: 10px;
} */
.dashboardActiveIndicator{
    background-color: aqua;
    width: 6px;
    height: 30px;
    position: absolute;
    left:0;
    border-radius: 0px 4px 4px 0px;
}