.publishMainContainer{
    display: flex;
    padding: 0px 3% 0px 3%;
}
.publishTopSection{
    display: flex;
    flex-direction: column;
}
.publishInfoContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 32px;
}
.publishInputComponent{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.selectComponent{
    width: 150px !important;
}
.publishInputsLabel{
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 5px;
    margin-left: 5%;
}
.publishInputs{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 24px;
    border: none;
    background: #F6F6F6;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    color: var(--color-placeholder)
}
.publishInputs:focus{
    outline: none;
}
.publishSelectInput{
    background: #F6F6F6;
    padding: 16px 24px;
    border: none;
    border-radius: 8px;
    color:black;
    font-weight: 400;
    font-size: 16px;
}
.hashtagListContainer{
    height:auto;
    display: flex;
    gap: 5%;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-top: 16px;
}
.publishHashtagCard{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    background: #F6F6F6;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    gap: 8px;
}
.publishHashtagCardClose{
    background-image: url('../../assets/icons/close.svg');
    width:15px;
    height: 15px;
    background-size: contain;
    cursor: pointer;
}
.summaryTextArea{
    width:100%;
    box-sizing: border-box;
    background-color: #F6F6F6;
    border: none;
    margin-top: 32px;
    padding: 16px 32px 16px 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #808080;
    height: 120px;
    font-family: mulish-regular;
    resize: none;
    border-radius: 8px;
}
.summaryTextArea:focus{
    outline: none;
}
.publishMiddleSection{
    display: flex;
    width: 100%;
    height: 80%;
    box-sizing: border-box;
    justify-content: space-between;
    margin-top:20px;
    padding-right: 30px
}
.quill {
    width: 60%;
    height: 75vh;
}
.smartphone {
    position: relative;
    width: calc(100% - 30px);
    /* height: min(640px, 100%); */
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
    max-height: 640px;
    aspect-ratio: 1/2;
}
.smartphone:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
}
.phonePowerButton {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
}
.smartphone .smartphoneContent {
    width: 100%;
    height: 100%;
    background: white;
    overflow-y: auto;
    padding: 0px 10px;
    box-sizing: border-box;
    word-wrap: break-word;
}
.smartphoneContent::-webkit-scrollbar-track {
    background: white;
}

.smartphoneContent::-webkit-scrollbar {
    width: 10px;
}
.smartphoneContent::-webkit-scrollbar-thumb {
    background: var(--color-secondary); 
    border-radius: 10px;
    width:5px;
}  
.publishBottomSection{
    margin-top: 30px;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    padding-bottom: 30px;
}
.publishButton{
    width: 160px;
    height: 48px
}
.publishButtonDisable{
    cursor: no-drop;
}
.draftButton{
    width: 160px;
    margin-right: 5%;
    height: 48px
}
/* Start of snackbar toast */
.snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: rgba(51,51,51,0.95);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 17px;
    border-radius: 10px;;
  }
  
  .snackbar[data-visible=true] {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 30px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;} 
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }