.homeHeaderMainContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background-color: #AB77FD;
    width: 100%;
    padding: 0 15%;
    box-sizing: border-box;
}
.homeHeaderLeftPanel{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
}
.homeHeaderLeftPanel > div {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px
}
.homeHeaderRightPanel{
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.homeHeaderUserProfile{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:20px;
}

.homeHeaderUsername{
    font-size: 16px;
    font-weight: 600;
}
.dropdownContainer{
    position: absolute;
    top: 70px;
    right: 0px;
    background-color: var(--color-BG);
    flex-direction: column;
    width: 166px;
    justify-content: space-between;
    align-items: center;
    color: #252525;
    font-weight: 400;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px rgba(206,206,206,0.3) solid;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.03);
}
.dropdownElement{
    padding: 20px;
    width: 60%;
    border-bottom: 1px solid #CECECE;
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: 10%;
    align-items: center;
}
.dropdownElement:last-of-type{
    border-bottom: none;
}
.homeHeaderRightPanel > span {
    font-weight: 600;
    font-size: 18px;
    line-height: 40px
}
a{
    text-decoration: none;
    color: black
}
@media screen and (max-width:1356px) and (min-width:599px){
    .homeHeaderRightPanel{
        width: 63%
    }
    .homeHeaderRightPanel > span {
        font-size: 16px;
    }
    .homeHeaderMainContainer{
        padding: 0 8.5% 0 8.5%
    }
}
@media screen and (max-width:599px){
    .homeHeaderLeftPanel{
        color: white
    }
    .homeHeaderLeftPanel > div{
        font-size: 16px;
    }
}
