.articlesPageMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    width: 78%
}

.articlesPageArticleContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 64px;
    box-sizing: border-box;
    margin-top: 40px;
    width: 100%;
}

.showMoreButton {
    width: 160px;
    height: 48px;
}