.mainContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.subContainer{
    max-width: 1960px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}