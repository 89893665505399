.deleteModalContainer{
    background: #F6F6F6;
    opacity: 1;
    border-radius: 8px;
    width: 35%;
    height: 224px;
    box-sizing: border-box;
    padding: 32px 32px 32px 32px;
    display: flex;
    flex-direction: column;
    gap: 32px
}
.deleteModalTopSection{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 28px
}
.deleteModalCaution{
    display: flex;
    flex-direction: column;
}
.deleteModalCautionTitle{
    font-weight: 700;
    font-size: 20px;
}
.deleteModalCautionBody{
    font-weight: 400;
    font-size: 16px;
}
.deleteModalBottomSection{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.deleteModalCancelBottom{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 131px;
    height: 48px;
    background: #F6F6F6;
    border-radius: 8px;
    color: black;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
}
.deleteModalDeleteBottom{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 131px;
    height: 48px;
    background: #FD3D39;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
}