.loginPageCover {
    width: 38%;
    background: url('../../../../assets/icons/cover.png');
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    background-size: 100% 100%;
    /* padding top defined inline */
    padding: 0 3%;
}

.loginPageCoverHeader {
    display: flex;
    gap: 10px;
    height: 10%;
}

.loginPageCovertitle {
    font-size: 32px;
    font-weight: 700;
}

.loginPageCoverWelcome {
    margin-bottom: 4%;
    margin-top: 10%;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    height: 73%;
}

.loginPageCoverMessage {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

/* Mobile versions */
.loginPageCoverMobileContainer {
    margin-top: 20%;
}