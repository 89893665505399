.homeFooterMainContainer{
    width: 100%;
    height: 384px;
    background-color: #1B1A24;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
}
.homeFooterSubContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 63%;
    height: 100%;
    padding: 3% 0px;
    box-sizing: border-box;
}
.homeFooterTitle{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    height: 96px;
}
.homeFooterlogoContainer{
    display: flex;
    align-items: center;  
    justify-content: center;
    width: 100%; 
    gap: 16px;
}
.homeFooterIcons{
    width: 32px;
    height: 32px
}
.homeFooterGeneralText{
    color: white
}
.homeFooterContentContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    font-size: 20px;
    font-weight: 700;
}
.homeFooterContentSubContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.homeFooterContentSubContainer:first-of-type{
    margin-bottom: 24px;
}
.homeFooterContentSubContainer:last-of-type{
    margin-top: 16px
}
.homeFooterContentIconContainer{
    display: flex;
    gap: 20%;
    justify-content: flex-end;
}
.seperatorLine{
    width: 100%;
    height: 1px;
    background-color: white;
}
/* ======================== Tablet style =========================*/
@media screen and (max-width:1365px) and (min-width:599px){
    .homeFooterSubContainer{
        width: 81%;
        height: 328px;
    }
    .homeFooterContentContainer{
        font-size: 16px;
    }
    .homeFooterTitle{
        font-size: 20px
    }
    .homeFooterIcons{
        width: 24px;
        height: 24px;
    }
}
/* ======================== Mobile style =========================*/
@media screen and (max-width: 599px){
    .homeFooterMainContainer{
        height: 200px
    }
    .homeFooterSubContainer{
        width: 85%
    }
    .homeFooterRow1{
        display: flex;
        height: 70%;
        justify-content: space-between;
        align-items: center;
        padding: 3% 0;
        box-sizing: border-box;
    }
    .homeFooterRow2{
        display: flex;
        height: 10%;
        justify-content: center;
        align-items: center;
    }
    .homeFooterContentIconContainer1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    .homeFooterContentIconContainer2{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .homeFooterGeneralText{
        font-size: 12px;
    }
    .homeFooterSocialMediaIcon{
        width: 24px;
        height: 24px
    }
    .homeFooterRightPanelMobile{
        color: white;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 33%;
        font-weight: 700;
        font-size: 12px;
        justify-content: space-between;
    }
}