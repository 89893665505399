.teacherArticlesPageMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.teacherArticleSections {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.teacherArticlesInput {
    border: none;
    background-color: #F6F6F6;
    height: 72px;
    border-radius: 15px;
    padding: 0 3% 0 3%;
    outline: none;
    font-weight: 400;
    font-size: 16px;
    color: #808080;
}

.teacherArticlesListMainContainer {
    margin-top: 24px;
    padding: 0px 0% 32px 0%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.teacherArticlesCardContainer {
    background-color: #F6F6F6;
    padding: 24px 32px 24px 32px;
    border-radius: 16px;
    cursor: pointer;
}

.teacherArticleSummary {
    font-weight: 400;
    font-size: 16px;
    display: block;
    overflow: hidden;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.6em;
    line-height: 1.8em;
}

.teacherArticleCreatedAt {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-placeholder)
}

.hashtagsContainer {
    display: flex;
    width: 55%;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-placeholder);
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.teacherArticleTopSectionLeft {
    display: flex;
    align-items: center;
}

.teacherArticleTopSectionLeftInfo {
    display: flex;
    flex-direction: column;
    margin-left: 25px
}

.teacherArticleTitle {
    font-weight: 700;
    font-size: 20px;
}

.teacherArticleUsername {
    font-weight: 400;
    font-size: 16px;
}

.articleListButton {
    height: 44px
}

.teacherArticleListNotFound {
    align-self: center;
    padding-top: 10%;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: 800;
}