.TeacherRegistrationListContainer{
    padding: 0% 0% 40px 0%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
}
.AdminTeacherRegisterColumn1{
    height: 100%;
    flex: 30px 1 1;
}

.AdminTeacherRegisterColumn2{
    height: 100%;
    flex: 30% 1 1 ;
    overflow: hidden;
}

.AdminTeacherRegisterColumn3{
    height: 100%;
    flex: 30% 1 5;
    overflow: hidden;
}

.AdminTeacherRegisterColumn4{
    height: 100%;
    flex: 30% 1 5;
    overflow: hidden;
}
.AdminTeacherRegisterColumn5{
    height: 100%;
    flex: 180px 1 0;
    justify-content: center;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}

