.articleDetailMainContainer {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 76%;
}

.articleShareContainer {
    height: 240px;
    background: linear-gradient(267.89deg, #408500 -1.58%, #4C9F00 32.34%, #4C9F00 65.06%, #408500 102.64%);
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 28px;
    padding-left: 68px;
}

.articleShareButton {
    width: 150px;
    height: 48px;
    background-color: #FCFCFC;
    color: black
}

.articleShareButton:hover {
    background-color: #FCFCFC;
}

.articleShareLeftPanel {
    color: white;
}

.articleShareRightPanel {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
    gap: 40px
}

.articleNotFound {
    font-size: 30px;
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}