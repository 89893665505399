.homeTopSectionContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%
}
.homePageTopSectionContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 116px;
    gap: 72px;
    background-image: url('../../assets/icons/homeBackground.svg');
    background-size: contain;
    background-repeat: no-repeat;
}
.homePageTopSectionTitle{
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    width: 40%;
    text-align: center;
}
.homePageSearchSection{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.searchResultBox{
    background: var(--color-BG);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-top: 32px;
    padding: 24px 44px 22px 24px;
    display: none;
    width: 100%;
    box-sizing: border-box;
}
.searchResultContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 157px;
    overflow-x: auto;
}
.searchResultContainer::-webkit-scrollbar-track {
    background: #CECECE;
    border-radius: 10px;
}
.searchResultContainer::-webkit-scrollbar {
    width: 7px;
}
.searchResultContainer::-webkit-scrollbar-thumb {
    background: var(--color-secondary); 
    border-radius: 10px;
}  
.SearchedItemContainer{
    width: 97%;
    padding: 8px 0px 8px 8px;
    border-top: 1px solid rgba(128, 128, 128, 0.2);
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    height: 52px;
    justify-content: center;
    cursor: pointer;
} 
.SearchedItemContainer:hover{
    background-color: #EFFCE3;
    border-top: 1px solid white;
}
.SearchedItemContainer:hover+div{
    border-top: 1px solid white;
}
.SearchedItemContainer:first-child{
    border-top: 1px solid white;
}  
.SearchedItemContainer:first-child{
    padding: 0px 0px 8px 8px;
} 
.searchedItemUsername{
    font-weight: 400;
    font-size: 14px;
    color: var(--color-placeholder);
}
.searchedItemTitle{
    font-weight: 700;
    font-size: 16px;
    color: black;
}
.recentText{
    font-weight: 700;
    font-size: 24px;
}
.articleCardMainContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
}
.homeCategoryText{
    font-weight: 400;
    font-size: 16px;
    color: var(--color-placeholder)
}
.articleCartTopSection{
    display: flex;
    justify-content: space-between;
}
.articleCartTopSectionLeft{
    display: flex;
    align-items: center;
}
.moreArticleButton{
    height: 48px;
    width: 181px;
    align-self: center;
    margin-top: 24px
}
@media screen and (max-width:1365px) and (min-width:800px) {
    .homePageTopSectionTitle{
        font-size: 32px;
        width: 90%;
    }  
    .homePageTopSectionContainer{
        gap: 24px;
        padding-top: 34px;
    }  
    .homePageSearchSection{
        width: 58%
    }
}
/* Define a mid break point within the tablet size */
@media screen and (max-width:800px) and (min-width: 599px){
    .homePageTopSectionTitle{
        font-size: 28px;
        width: 90%;
    }  
    .homePageTopSectionContainer{
        gap: 24px;
        padding-top: 34px;
    } 
    /* Original style lives in index.css but for mobile style overwrite some styles */
    .homePageSearchBoxContainer{
        height: 48px;
    }
    .homePageSearchSection{
        width: 66%
    }
}
@media screen  and (max-width: 599px){
    .homePageTopSectionTitle{
        font-size: 20px;
        width: 90%;
    }
    .homePageTopSectionContainer{
        gap: 24px;
        padding-top: 0px;
        background-size: cover;
    }
    .homePageSearchSection{
        width: 90%
    }
    /* Original style lives in index.css but for mobile style overwrite some styles */
    .homePageSearchBoxContainer{
        height: 40px;
    }
    /* Original style lives in index.css but for mobile style overwrite some styles */
    .homePageSearchButton{
        visibility: hidden;
    }
    .recentText{
        display: none;
    }
}