.adminArticlesContainer{
    padding: 0px 4% 0px 4%;
}
.adminArticlesGridStyle{
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 24px;
    row-gap: 24px;
}
.adminArticlesCardContainer{
    height: 232px;
    background: #F6F6F6;
    border-radius: 15px;
    padding: 16px
}
/* @media screen and (max-width: var(--metric-mobile-break-point)) {
    .adminArticlesGridStyle{
        background-color: aqua;
    }
} */