.success{
    border-bottom: 5px #8EC23F solid;
}
.fail{
    border-bottom: 9px #FD3D39 solid;
}
.MessagesMainContainer{
    position: fixed;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    top:5%;
    gap: 10px;
    z-index: 3;
}
.MessagesSubContainer{
    width: 50%;
    height: 60px;
    padding: 10px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #FCFCFC;
    font-weight: 700;
    font-size: 14px;
    color: #252525;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
}
.progress-ring__circle {
    transition: 0.35s stroke-dashoffset;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  