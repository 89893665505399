.input-group{
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-bottom: 16px;
    width: 100%;
}
.input-group > .error {
    border: 2px solid red;
}
.user-label {
    display: flex;
    transition: 0.5s;
    z-index: 2;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: var(--color-placeholder);
    transform: translate(15px, 14px);
    margin-bottom: 5px;
    padding: 0px 10px;
    background-color: white;
}
.user-input {
    z-index: 0;
    height: 48px;
    width: 100%;
    border-radius: 12px;
    border: 2px solid var(--color-placeholder);
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    padding: 0px 20px;
    color: #252525;
    outline: none;
}
.user-input:focus+.user-label{
    transform: translate(0px, 0px);
}