.homeSideBarMainContainer{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
    position: fixed;

}
.homeSideBarSubContainer{
    height: 100vh;
    width: 200;
    background-color: #1B1A24;
    position: fixed;
    left: 0;
    width: min(70%,260px);
    border-radius: 0 45px 45px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.homeSideBarTitleContainer{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    font-size: 20px;
    font-weight: 700;
}
.homeSideBarMenuContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 18%;
    color: white;
    height: 65%;
}
.homeSideBarMenu{
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;;
    height: 90px;
    gap: 16px;
    font-size: 16px;
    font-weight: 400;
}
.homeSideBarMenu[data-border='true']{
    border-bottom: 1px solid rgba(246,246,246,0.2);
}
.homeSideBarMenuLogout{
    margin-top: 50%;
}