
.registerModalMainContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.registerModalTitle{
    background-image: linear-gradient(45deg, #489400, #84BF04);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 36px;
    font-weight: bold;
    font-family: 'mulish-regular';
}
.registerModalGif{
    width: 20vmin;
    height: 20vmin;
}
.registerModalMainText{
    font-size:24px;
    font-weight:400;
}
.registerModalButton{
    background-color: var(--color-primary);
    border: none;
    border-radius: 8px;
    width: 100%;
    height: 56px;
    color: white;
    font-size: 20px;
    font-weight: 700;
    font-family: 'mulish-regular';
    cursor: pointer;
}

@media screen and (min-width: 599px) and (max-width: 800px){
    .registerModalTitle{
        font-size: 24px;
        font-weight: 700;
    }
    .registerModalMainText{
        font-size:20px;
        font-weight:700;
    }
    .registerModalButton{
        font-size: 18px;
        font-weight: 700;
        background-color: aqua;
    }
}

/* Mobile size */
@media screen and (max-width: 599px){
    .registerModalTitle{
        font-size: 20px;
        font-weight: 700;
    }
    .registerModalMainText{
        font-size:14px;
        font-weight:700;
    }
    .registerModalButton{
        height: 48px;
        font-size: 14px;
        font-weight: 700;
    }
}
