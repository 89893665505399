.forgetPassModalContainer {
    width: 36%;
    background-color: var(--color-BG);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 2% calc(min(51px, 8%));
    box-sizing: border-box;
    border-radius: 11px;
}

.forgetPasswordBanner {
    width: 65%;
    height: 10%;
}

.forgetPasswordBodyContainer {
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 28px;
}

.submitButton {
    height: 6vh
}

.forgetPasswordClose {
    position: absolute;
    right: 6%;
    top: 10%;
    cursor: pointer;
}

.forgetPasswordTitle {
    font-weight: 700;
    font-size: 20px;
}

.authPagesMainContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.loginFormMainContainer {
    width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
    z-index: 0;
}

@media screen and (min-width:500px) and (max-width:1350px) {
    .loginFormMainContainer {
        flex: 1;
        padding-right: 2%
    }
}

.loginFormTitle {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.userAuthCloseButton {
    cursor: pointer;
}

.rememberContainer {
    display: flex;
    align-items: center;
    gap: 2%;
    margin-top: 16px;
    margin-bottom: 24px;
}

.rememberContainer>input {
    color: white;
    accent-color: var(--color-primary);
    width: 24px;
    height: 24px
}

.rememberContainer>span {
    font-weight: 400;
    font-size: 16px;
}

.loginFormSubmitButton {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 0px;
    background-color: var(--color-primary);
    color: white;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 16px;
}

.loginFormSubmitButton[disabled] {
    background-color: var(--color-placeholder);
    cursor: no-drop;
}

.loginFormLastText {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 8px
}

.loginFormLastTextLink {
    color: #1E88E5;
    cursor: pointer;
}

/* Mobile Design */
@media screen and (max-width: 599px) {
    .authPagesMainContainer {
        flex-direction: column;
        align-items: center;
    }

    .loginFormMainContainer {
        width: 100%;
        margin: 0px;
        padding: 0px 8% 0px 8%;
        box-sizing: border-box;
    }

    .input-group {
        width: 100%;
    }
}