.AdminTeacherListMainContainer{
    padding: 0% 0% 40px 0%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
}
.addTeacherButton{
    width: 14%;
    height: 48px;
    align-self: flex-end;
    margin-right: 5%;
}
.AdminTeacherListTableMainContainer{
    width: 90%;
    border-radius: 15px;
    box-sizing: border-box;
    outline: 1px solid rgba(0,0,0,0.1);
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
}
.AdminTeacherListTableHeaderContainer{
    height: 60px;
    width: 100%;
    background-color: var(--color-primary);
    display: flex;
}
.AdminTeacherTableHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white
}
.AdminTeacherListTableContentContainer{
    height: 90%;
    width: 100%;
}
.AdminTeacherListColumn1{
    height: 100%;
    flex: 30px 1 1;
}

.AdminTeacherListColumn2{
    height: 100%;
    flex: 40px 1 1 ;
}

.AdminTeacherListColumn3{
    height: 100%;
    flex: 30% 1 5;
}

.AdminTeacherListColumn4{
    height: 100%;
    flex: 30% 1 5;
}

.AdminTeacherListColumn5{
    height: 100%;
    flex: 30% 1 5;
}

.AdminTeacherListColumn6{
    height: 100%;
    flex: 30px 1 0;
}
.AdminTeacherListTableContentElementContainer{
    width: 100%;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.AdminTeacherListTableContentElement{
    display: flex;
    justify-content: center;
    align-items: center;
}
.tableBorder{
    border-bottom: 1px #cecece solid;
}
.noTeacherErrorMessage{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    height: 100%
}