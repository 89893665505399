.addTeacherModalContainer{
    background: #F6F6F6;
    opacity: 1;
    border-radius: 8px;
    width: 35%;
    box-sizing: border-box;
    padding: 32px 32px 32px 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
}
.addTeacherModalTitle{
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 24px;
}
.addTeacherModalCloseButton{
    position: absolute;
    right: 32px;
    top: calc(32px + 5px);
    cursor: pointer;
}
.clickableText{
    color: blue;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    color: #00ACC1;
}
.createAccountButton{
    width: 100%;
    height: 48px;
    margin-top: 24px;
    
}