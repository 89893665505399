.teacherProfileContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.teacherProfileSections{
    width: 600px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.teacherProfileEditFormContainer{
    width: 70%;
    align-self: flex-start;
    margin-left: 10%;
}
.teacherProfileImageEditContainer{
    position: relative;
    display: inline-flex;
}
input[type='file']{
    display: none;
}
.imageUploader{
    cursor: pointer;
    background-color: var(--color-primary);
    border: none;
    border-radius: 80px;
    padding: 10px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 110px;
    right: 0px;
    z-index: 2;
}
.teacherProfileSubmit{
    width: 25%;
    height: 56px;
    width: 166px;
}