.notFoundContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 8% 0px 8% 0px;
}

.notFoundFirstText {
    font-weight: 700;
    font-size: 256px;
    color: #808080;
}

.notFoundSecondText {
    font-weight: 700;
    font-size: 32px;
    color: #808080;
    text-align: center;
}

.goHomeButton {
    width: 15%;
    height: 64px
}

@media screen and (max-width:1365px) and (min-width:599px) {
    .notFoundFirstText {
        font-size: 224px;
    }

    .notFoundSecondText {
        font-size: 32px;
    }

    .goHomeButton {
        width: 30%;
        height: 64px
    }
}

@media screen and (max-width:599px) {
    .notFoundContainer {
        justify-content: center;
        padding: 0px 5%;
        gap: 3%
    }

    .notFoundFirstText {
        font-size: 48px;
    }

    .notFoundSecondText {
        font-size: 20px;
    }

    .goHomeButton {
        width: 60%;
        height: 40px
    }
}